import React from 'react'
import { graphql } from 'gatsby'
import Meta from '../components/Meta'
import Layout from '../components/Layout'
import Header from '../components/Header'
import PageHeading from '../components/PageHeading'
import Footer from '../components/Footer'
import { RichTextElement } from "@kentico/gatsby-kontent-components"
import { Link } from 'gatsby'


class NotFoundRoute extends React.Component {
    render() {
        const contactPageData = this.props.data
        const title = contactPageData.kontentItemSiteMetadata.elements.title.value
        const pageNotFoundMessage = contactPageData.kontentItemSiteMetadata.elements.page_not_found_message.value

        return (
            <Layout>
                <div>
                    <Meta title={"Stránka nenalezena"} postfix={title} description={pageNotFoundMessage} />
                    <PageHeading data="Stránka nenalezena" />
                    <section className="error-page">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-10 offset-lg-1 align-self-center">
                                    <div className="error-container">
                                        <div className="row">
                                            <div className="col-lg-6 align-self-center">
                                                <h2>404</h2>
                                            </div>
                                            <div className="col-lg-6">
                                                <RichTextElement
                                                    value={pageNotFoundMessage} />
                                                <div className="main-white-button">
                                                    <Link to="/" data-tracking-label="404_Home">Na úvodní stránku</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </Layout >
        )
    }
}

export default NotFoundRoute

export const pageQuery = graphql`
  query NotFoundQuery {
    kontentItemSiteMetadata(system: {codename: {eq: "site_metadata"}}) {
      elements {
        subtitle {
          value
        }
        title {
          value
        }
        page_not_found_message {
          value
        }
      }
    }
  }
`
